// Libraries
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
// Components
import Container from "@Components/Container/Container"
import NavbarItem, { NavbarItemProps } from "./NavbarItem"
// Assets
import LogoDark from "@Icons/LogoDark"
// Hooks
import useWindowScroll from "@Hooks/useWindowScroll"
// Types
import { Tag } from "@Types/tags"
// Styles
import * as styles from "./Navbar.module.scss"

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isScrolled, setisScrolled] = useState(false)
  const scrollY = useWindowScroll()
  const { t, i18n } = useTranslation()

  const toggleExpandHandler = () => setIsExpanded(!isExpanded)

  const menuItems: NavbarItemProps[] = [
    {
      text: t("routes.about.text"),
      link: t("routes.about.link"),
      toggleExpandHandler,
      children: [
        {
          text: t("routes.aboutChildren.history.text"),
          link: t("routes.aboutChildren.history.link"),
        },
        {
          text: t("routes.aboutChildren.authorizations.text"),
          link: t("routes.aboutChildren.authorizations.link"),
        },
        {
          text: t("routes.project.text"),
          link: t("routes.project.link"),
        },
      ],
    },
    {
      text: t("routes.newses.text"),
      link: t("routes.newses.link"),
      toggleExpandHandler,
    },
    {
      text: t("routes.offer.text"),
      link: t("routes.offer.link"),
      toggleExpandHandler,
      children: [
        {
          text: t("routes.offers.coldStores.text"),
          link: t("routes.offers.coldStores.link"),
        },
        {
          text: t("routes.offers.chests.text"),
          link: t("routes.offers.chests.link"),
        },
        {
          text: t("routes.offers.containers.text"),
          link: t("routes.offers.containers.link"),
        },
        {
          text: t("routes.offers.brigadiers.text"),
          link: t("routes.offers.brigadiers.link"),
        },
        {
          text: t("routes.offers.disabled.text"),
          link: t("routes.offers.disabled.link"),
        },
        {
          text: t("routes.offers.ninePersons.text"),
          link: t("routes.offers.ninePersons.link"),
        },
        {
          text: t("routes.offers.bank.text"),
          link: t("routes.offers.bank.link"),
        },
        {
          text: t("routes.offers.special.text"),
          link: t("routes.offers.special.link"),
        },
      ],
    },
    {
      text: t("routes.work.text"),
      link: t("routes.work.link"),
      toggleExpandHandler,
    },
    {
      text: t("routes.service.text"),
      link: t("routes.service.link"),
      toggleExpandHandler,
    },
    {
      text: t("routes.contact.text"),
      link: t("routes.contact.link"),
      toggleExpandHandler,
    },
    // {
    //   text: t("routes.download.text"),
    //   link: t("routes.download.link"),
    //   isHiglited: 'basic',
    //   toggleExpandHandler,
    // },
  ]

  const menuLanguages: NavbarItemProps[] = [
    {
      text: "FR",
      link: "https://www.groupegruau.com/",
      isCurrentLang: false,
      toggleExpandHandler,
    },
    {
      text: "EN",
      link: "/en/",
      isCurrentLang: i18n.language === "en",
      toggleExpandHandler,
    },
    {
      text: "PL",
      link: "/",
      isCurrentLang: i18n.language === "pl",
      toggleExpandHandler,
    },
  ]

  useEffect(() => {
    setisScrolled(scrollY > 50)
  }, [scrollY])

  return (
    <Container
      as={Tag.Nav}
      classes={classNames(styles.navbar, {
        [styles.navbarExpanded]: isExpanded,
        [styles.navbarScrolled]: isScrolled,
      })}
    >
      <Link
        to={t("routes.home.link")}
        className={styles.navbarLogo}
        onClick={() => setIsExpanded(false)}
      >
        <StaticImage src="../../../assets/images/logo.png" alt="gruau" />
      </Link>
      <button
        onClick={toggleExpandHandler}
        className={styles.navbarButton}
        aria-label="menu"
      >
        <span></span>
        <span></span>
      </button>
      <ol className={styles.navbarMenu}>
        {menuItems.map(item => (
          <NavbarItem key={item.text} {...item} />
        ))}
        <li className={styles.navbarMenuService}>
          <NavbarItem
            as="span"
            text={t("routes.download.text")}
            link={t("routes.download.link")}
            isHiglited={"outline"}
            toggleExpandHandler={toggleExpandHandler}
          />
          <span className={styles.languages}>
            {menuLanguages.map(item => (
              <NavbarItem as="span" key={item.text} {...item} />
            ))}
          </span>
        </li>
      </ol>
    </Container>
  )
}

export default Navbar
